import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from 'jetswap-uikit-new/dist/theme'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Kanit', sans-serif;
  }
  body {
    background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }

    .flex {
      display: flex;
    }
    .flex-column {
        flex-direction: column;
    }
    .align-center {
        align-items: center;
    }
    .just-center {
        justify-content: center;
    }
    .just-between {
        justify-content: space-between;
    }
    .just-around {
        justify-content: space-around;
    }
    .just-end {
        justify-content: flex-end;
    }
    .center {
        text-align: center;
    }
    .right {
        text-align: right;
    }
    .left {
        text-align: left;
    }
    .pointer {
        cursor: pointer;
    }
    .ant-modal-content {
      background-color: transparent;
    }
    .ant-modal-body {
      padding: 0;
      border-radius: 20px;
    }
  }
`

export default GlobalStyle

import tokens from './tokens'
import ftokens from './ftokens'
import { MarketConfig } from './types'

const markets: MarketConfig[] = [
  {
    id: 'fts',
    symbol: 'FTS',
    token: tokens.fts,
    fsymbol: 'fFTS',
    ftoken: ftokens.fts,
    asset: '/images/lending/coins/fts.svg',
    fasset: '/images/lending/coins/ffts.svg',
  },
  {
    id: 'usdc',
    symbol: 'USDC',
    token: tokens.usdc,
    fsymbol: 'fUSDC',
    ftoken: ftokens.usdc,
    asset: '/images/lending/coins/usdc.png',
    fasset: '/images/lending/coins/fusdc.svg',
  },
  {
    id: 'usdt',
    symbol: 'USDT',
    token: tokens.usdt,
    fsymbol: 'fUSDT',
    ftoken: ftokens.usdt,
    asset: '/images/lending/coins/usdt.png',
    fasset: '/images/lending/coins/fusdt.svg',
  },
  {
    id: 'busd',
    symbol: 'BUSD',
    token: tokens.busd,
    fsymbol: 'fBUSD',
    ftoken: ftokens.busd,
    asset: '/images/lending/coins/busd.png',
    fasset: '/images/lending/coins/fbusd.svg',
  },
  {
    id: 'bnb',
    symbol: 'BNB',
    fsymbol: 'fBNB',
    ftoken: ftokens.bnb,
    asset: '/images/lending/coins/bnb.png',
    fasset: '/images/lending/coins/fbnb.svg',
  },
  {
    id: 'btcb',
    symbol: 'BTCB',
    token: tokens.btcb,
    fsymbol: 'fBTCB',
    ftoken: ftokens.btcb,
    asset: '/images/lending/coins/btcb.png',
    fasset: '/images/lending/coins/fbtcb.svg',
  },
  {
    id: 'eth',
    symbol: 'ETH',
    token: tokens.eth,
    fsymbol: 'fETH',
    ftoken: ftokens.eth,
    asset: '/images/lending/coins/eth.png',
    fasset: '/images/lending/coins/feth.svg',
  },
  {
    id: 'ltc',
    symbol: 'LTC',
    token: tokens.ltc,
    fsymbol: 'fLTC',
    ftoken: ftokens.ltc,
    asset: '/images/lending/coins/ltc.png',
    fasset: '/images/lending/coins/fltc.svg',
  },
  {
    id: 'xrp',
    symbol: 'XRP',
    token: tokens.xrp,
    fsymbol: 'fXRP',
    ftoken: ftokens.xrp,
    asset: '/images/lending/coins/xrp.png',
    fasset: '/images/lending/coins/fxrp.svg',
  },
  {
    id: 'ada',
    symbol: 'ADA',
    token: tokens.ada,
    fsymbol: 'fADA',
    ftoken: ftokens.ada,
    asset: '/images/lending/coins/ada.png',
    fasset: '/images/lending/coins/fada.svg',
  },
  {
    id: 'dai',
    symbol: 'DAI',
    token: tokens.dai,
    fsymbol: 'fDAI',
    ftoken: ftokens.dai,
    asset: '/images/lending/coins/dai.png',
    fasset: '/images/lending/coins/fdai.svg',
  },
  {
    id: 'beth',
    symbol: 'BETH',
    token: tokens.beth,
    fsymbol: 'fBETH',
    ftoken: ftokens.beth,
    asset: '/images/lending/coins/beth.png',
    fasset: '/images/lending/coins/fbeth.svg',
  },
  {
    id: 'dot',
    symbol: 'DOT',
    token: tokens.dot,
    fsymbol: 'fDOT',
    ftoken: ftokens.dot,
    asset: '/images/lending/coins/dot.png',
    fasset: '/images/lending/coins/fdot.svg',
  },
  {
    id: 'cake-lp-fts-bnb-v2',
    symbol: 'Cake-LP-FTS-BNB-v2',
    token: {
      symbol: 'Cake-LP-FTS-BNB-v2',
      address: {
        56: '0xc69f2139a6Ce6912703AC10e5e74ee26Af1b4a7e',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://jetfuel.finance/',
    },
    fsymbol: 'fCake-LP-FTS-BNB-v2',
    ftoken: ftokens['cake-lp-fts-bnb-v2'],
    asset: '/images/lending/coins/cake-lp-fts-bnb-v2.png',
    fasset: '/images/lending/coins/fcake-lp-fts-bnb-v2.svg',
  },
]

export default markets

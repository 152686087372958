/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import marketsConfig from 'config/constants/markets'
import fetchMarkets from './fetchMarkets'

const initialState: any = { data: [...marketsConfig], markets: {} }

export const marketsSlice = createSlice({
  name: 'Markets',
  initialState,
  reducers: {
    setMarketsPublicData: (state, action) => {
      const { markets } = action.payload
      state.markets = {
        ...state.markets,
        ...markets,
      }
    },
    updateMarketData: (state, action) => {
      const { data } = action.payload
      state.markets = {
        ...state.markets,
        ...data,
      }
    },
  },
})

// Actions
export const { setMarketsPublicData, updateMarketData } = marketsSlice.actions

// Thunks
export const fetchMarketsPublicDataAsync = (accountAddress, marketData) => async (dispatch) => {
  const markets = await fetchMarkets(accountAddress, marketData)
  dispatch(setMarketsPublicData({ markets }))
}

export const updateMarkets = (data) => async (dispatch) => {
  dispatch(updateMarketData({ data }))
}

export default marketsSlice.reducer

import { MenuEntry } from 'jetswap-uikit-new'
import { ContextApi } from 'contexts/Localization/types'

const config: (t: ContextApi['t']) => MenuEntry[] = (t) => [
  {
    label: t('Home'),
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: t('Trade'),
    icon: 'TradeIcon',
    items: [
      {
        label: t('Exchange'),
        href: 'https://exchange.jetswap.finance/#/swap',
      },
      {
        label: t('Liquidity'),
        href: 'https://exchange.jetswap.finance/#/pool',
      },
    ],
  },
  {
    label: t('Farms'),
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: t('Pools'),
    icon: 'PoolIcon',
    href: '/pools',
  },
  {
    label: t('Vaults'),
    icon: 'VaultIcon',
    href: '/vaults',
  },
  // {
  //   label: 'Fortress Lending',
  //   icon: 'LendingIcon',
  //   href: '/lending',
  // },
  // {
  //   label: t('Lottery'),
  //   icon: 'DiceIcon',
  //   items: [
  //     {
  //       label: t('Dice Game'),
  //       href: '/dice'
  //     },
  //   ]
  // },
  // {
  //   label: 'Collectibles',
  //   icon: 'NftIcon',
  //   href: '/collectibles',
  // },
  // {
  //   label: 'Teams & Profile',
  //   icon: 'GroupsIcon',
  //   calloutClass: 'rainbow',
  //   items: [
  //     {
  //       label: 'Leaderboard',
  //       href: '/teams',
  //     },
  //     {
  //       label: 'Task Center',
  //       href: '/profile/tasks',
  //     },
  //     {
  //       label: 'Your Profile',
  //       href: '/profile',
  //     },
  //   ],
  // },
  {
    label: t('Info'),
    icon: 'InfoIcon',
    items: [
      {
        label: t('Overview'),
        href: 'https://info.jetswap.finance/home',
        target: '_blank',
      },
      {
        label: t('Tokens'),
        href: 'https://info.jetswap.finance/tokens',
        target: '_blank',
      },
      {
        label: t('Pairs'),
        href: 'https://info.jetswap.finance/pairs',
        target: '_blank',
      },
      {
        label: t('Accounts'),
        href: 'https://info.jetswap.finance/accounts',
        target: '_blank',
      },
    ],
  },
  // {
  //   label: 'IJO',
  //   icon: 'IfoIcon',
  //   href: '/ijo',
  // },
  // {
  //   label: t('Contest'),
  //   icon: 'ContestIcon',
  //   items: [
  //     {
  //       label: t('Weekly Trading Contest'),
  //       icon: 'ContestIcon',
  //       href: '/trading',
  //     },
  //   ],
  // },
  {
    label: t('Jets & Gforce'),
    icon: 'JetsIcon',
    items: [
      {
        label: 'JETS',
        icon: 'JetsIcon',
        href: '/jets',
      },
      {
        label: t('Gforce'),
        icon: 'GforceIcon',
        href: '/gforce',
      },
    ],
  },
  {
    label: t('Partnership'),
    icon: 'PartnerIcon',
    href: 'https://forms.gle/gZ6EnfJtiooEr1mB7',
  },
  {
    label: t('Audit'),
    icon: 'AuditIcon',
    href: 'https://docs.jetswap.finance/audits-and-security'
  },
  {
    label: t('Apply Job'),
    icon: 'DiceIcon',
    items: [
      {
        label: t('Dev'),
        href: 'https://t.me/codehealer',
      },
      {
        label: t('Design'),
        href: 'https://t.me/codehealer',
      },
      {
        label: t('NFT Artist'),
        href: 'https://t.me/codehealer',
      },
    ],
  },
  // {
  //   label: t('Lottery'),
  //   icon: 'TicketIcon',
  //   href: '/lottery',
  // },
  {
    label: t('More'),
    icon: 'MoreIcon',
    items: [
      {
        label: t('Docs'),
        href: 'https://docs.jetswap.finance',
        target: '_blank',
      },
      {
        label: t('Blog'),
        href: 'https://jetfuelfinance.medium.com/',
        target: '_blank',
      },
      {
        label: t('Vote'),
        href: 'https://vote.jetswap.finance/',
        target: '_blank',
      },
      {
        label: t('Jetfuel Finance'),
        href: 'https://jetfuel.finance',
        target: '_blank',
      },
      {
        label: t('Fortress'),
        href: 'https://fortress.loans',
        target: '_blank',
      },
      {
        label: t('Gforce'),
        href: 'https://jetfuel.finance/gforce',
        target: '_blank',
      },
    ],
  },
]

export default config

export default {
  wings: {
    97: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    56: '0x0487b824c8261462f88940f97053e65bdb498446',
  },
  jets: {
    97: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    56: '0xf6488205957f0b4497053d6422F49e27944eE3Dd',
  },
  wbnb: {
    97: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  },
  busd: {
    97: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    56: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  },
  fuel: {
    97: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    56: '0x2090c8295769791ab7A3CF1CC6e0AA19F35e441A',
  },
  fts: {
    97: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    56: '0x4437743ac02957068995c48E08465E0EE1769fBE',
  },
  usdc: {
    97: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    56: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  },
  dai: {
    97: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    56: '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
  },
  usdt: {
    97: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    56: '0x55d398326f99059fF775485246999027B3197955',
  },
  btcb: {
    97: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    56: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
  },
  eth: {
    97: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    56: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  },
  bhc: {
    97: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    56: '0x6fd7c98458a943f469E1Cf4eA85B173f5Cd342F4',
  },
  tryon: {
    97: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    56: '0x050f65BEf6bd15De57462cf75d06BafEB2A59118',
  },
  gfcev1: {
    97: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    56: '0x94BaBBE728D9411612Ee41b20241a6FA251b26Ce',
  },
  gfcev2: {
    97: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    56: '0x5f136383E230F972739FaE2E81E7E774AfE64c66',
  },
  masterChef: {
    97: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    56: '0x63d6EC1cDef04464287e2af710FFef9780B6f9F5',
  },
  pancakeMasterChef: {
    97: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
  },
  apeswapMasterChef: {
    97: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    56: '0x5c8D727b265DBAfaba67E050f2f739cAeEB4A6F9',
  },
  babyswapMasterChef: {
    97: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    56: '0xdfAa0e08e357dB0153927C7EaBB492d1F60aC730',
  },
  autoFarmMasterChef: {
    97: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    56: '0x0895196562c7868c5be92459fae7f877ed450452',
  },
  fortressIUnitroller: {
    97: '',
    56: '0x67340Bd16ee5649A37015138B3393Eb5ad17c195',
  },
  venusIUnitroller: {
    97: '',
    56: '0xfD36E2c2a6789Db23113685031d7F16329158384',
  },
  sousChef: {
    97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    56: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
  },
  lottery: {
    97: '0x99c2EcD51d52c036B00130d882Bc65f20Fdecf9f',
    56: '0xf8a50ea685D08b0Bd985D67FC73d9E4c6E08c61a',
  },
  lotteryNFT: {
    97: '0x8175c10383511b3a1C68f9dB222dc14A19CC950e',
    56: '0x4eC15bEac991dBC84B7bb395284c53687af03Ff6',
  },
  mulltiCall: {
    56: '0x819801d9048EC5D1DEb04E72b3B2dA4bd8E5ca9e',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  pancakeProfile: {
    56: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
    97: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
  },
  pancakeRabbits: {
    56: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
    97: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
  },
  bunnyFactory: {
    56: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf',
    97: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
  },
  claimRefund: {
    56: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    97: '',
  },
  pointCenterIfo: {
    56: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
    97: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
  },
  bunnySpecial: {
    56: '0xFee8A195570a18461146F401d6033f5ab3380849',
    97: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  dryonPresale: {
    56: '0xFc432aA24ddb0F29133B32b0D1cf9084615Ea1db',
    97: '',
  },
  wingsBnbPair: {
    56: '0x1CD0fe829d83Fb49c8831Ae860d19c6062adA6e9',
    97: '',
  },
  wingsBusdPair: {
    56: '0xFBa740304f3fc39d0e79703a5D7788E13f877DC0',
    97: '',
  },
  hpsBnbPair: {
    56: '0x918726Fd1C8Fb80E7C9A01a75DD057F0DE3457eb',
    97: '',
  },
  comptroller: {
    97: '0xB24DaCE5343A97fc0E82584Ecd52c7e54ABBda09',
    56: '0x67340Bd16ee5649A37015138B3393Eb5ad17c195',
  },
  repayMax: {
    97: '0xfcafb139a9208D4Fe1D28D5dF986430e915fB009',
    56: '0x4E07a544ead53dce172C62cCF0AB03AF1b25a795',
  },
  faiToken: {
    97: '0x3B13b1af99b6d75D532C4E234fb2c3aE62744b73',
    56: '0x10a450A21B79c3Af78fb4484FF46D3E647475db4',
  },
  faiVault: {
    97: '0x0a5b2eE609514153B307FBdceB52C68199BaD712',
    56: '0x066807c7B22c6c0a7fa370A2cA812e5Fc22DBef6',
  },
}

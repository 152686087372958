import { Ifo } from './types'

const ifos: Ifo[] = [
  {
    id: 'defi',
    address: '0x51457145187e3B6deDCd10808f1A49EFC08B31dC',
    isActive: true,
    name: 'DEFIYIELD',
    subTitle: 'DeFi Investing & Yield Farming Platform',
    description:
      'Your gateway to Decentralized Finance | Manage assets across blockchains, generate returns from cryptocurrencies, access the best token swap rates and more',
    launchDate: 'Mar. 31',
    launchTime: '5PM GMT',
    saleAmount: '833,333 DEFI',
    raiseAmount: '$200,000',
    cakeToBurn: '$1,500,000',
    projectSiteUrl: 'https://defiyield.app',
    currency: 'BUSD',
    currencyAddress: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    currencyDecimals: 18,
    tokenDecimals: 18,
    tokenSymbol: 'DEFI',
    releaseBlockNumber: 16464053,
    campaignId: '51150516411080000',
    maxDepositAmount: 20000,
  },
  {
    id: 'buzz2nd',
    address: '0xD197F91E2fC06456a0614DD2162C039920fCC776',
    isActive: false,
    name: 'Cosmosium Finance',
    subTitle: 'Decentralized Finance with yield aggregation and strategy tradings',
    description:
      'Cosmosium Finance simplifies earning system on DeFi with migrating yield aggregations and indexing systems. BUZZ token is utilization token for all Cosmosium products.',
    launchDate: 'Feb. 11',
    launchTime: '5PM GMT',
    saleAmount: '3,159,000 BUZZ',
    raiseAmount: '$90,000',
    cakeToBurn: '$1,500,000',
    projectSiteUrl: 'https://app.cosmosium.finance',
    currency: 'BUSD',
    currencyAddress: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    currencyDecimals: 18,
    tokenDecimals: 18,
    tokenSymbol: 'BUZZ',
    releaseBlockNumber: 15051641,
    campaignId: '51150516411080000',
    maxDepositAmount: 5000,
  },
  {
    id: 'buzz',
    address: '0xeDc54A3f2eFFBA3B446Ed7d08fCEDD6A8e0540a3',
    isActive: false,
    name: 'Cosmosium Finance',
    subTitle: 'Decentralized Finance with yield aggregation and strategy tradings',
    description:
      'Cosmosium Finance simplifies earning system on DeFi with migrating yield aggregations and indexing systems. BUZZ token is utilization token for all Cosmosium products.',
    launchDate: 'Feb. 10',
    launchTime: '10AM GMT',
    saleAmount: '3,510,000 BUZZ',
    raiseAmount: '$100,000',
    cakeToBurn: '$1,500,000',
    projectSiteUrl: 'https://app.cosmosium.finance',
    currency: 'BUSD',
    currencyAddress: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    currencyDecimals: 18,
    tokenDecimals: 18,
    tokenSymbol: 'BUZZ',
    releaseBlockNumber: 15051641,
    campaignId: '51150516411080000',
    maxDepositAmount: 5000,
  },
  {
    id: 'ogmn',
    address: '0xc8Be300Cc14ce7B611D2C7D72Bcbf2E530A063f6',
    isActive: false,
    name: 'Origami Moon',
    subTitle: 'The Worlds Fastest Growing Deflationary, Rug-proof, Auto-Liquidity, Passive Yield Token',
    description:
      'OrigamiMoon is a revolutionary new friction-less yield generating token, that rewards its holders using a 10% tax collected on all transactions, and has implemented many new features to improve on the tokenomics of the past. Everyone from the developers to the marketing team has the experience, knowledge, and passion to advance DeFi to the next generation.',
    launchDate: 'Aug. 25',
    launchTime: '5PM GMT',
    saleAmount: '500,000,000,000,000 OGMN',
    raiseAmount: '$150,000',
    cakeToBurn: '$1,500,000',
    projectSiteUrl: 'https://origamimoon.io/',
    currency: 'WINGS-BNB',
    currencyAddress: '0x1cd0fe829d83fb49c8831ae860d19c6062ada6e9',
    currencyDecimals: 18,
    tokenDecimals: 9,
    tokenSymbol: 'OGMN',
    releaseBlockNumber: 8127180,
    campaignId: '511080000',
    maxDepositAmount: 125,
  },
]

export default ifos

import BigNumber from 'bignumber.js'
import web3NoAccount from 'utils/web3'
import { getAddress } from 'utils/addressHelpers'
import { getVbepContract, getTokenContract, getComptrollerContract } from 'utils/contractHelpers'

import marketsConfig from 'config/constants/markets'

const fetchMarkets = async (accountAddress, marketData) => {
  let totalSupplyBalance = new BigNumber(0)
  let totalBorrowBalance = new BigNumber(0)
  let totalBorrowLimit = new BigNumber(0)
  let totalLiquidity = new BigNumber(0)
  let fortressEarned = new BigNumber(0)

  if (!marketData.markets || !accountAddress) return {}

  const web3 = web3NoAccount

  const compContract = getComptrollerContract()

  const fortressInitialIndex = await compContract.methods.fortressInitialIndex().call()

  // // Total Fai Staked
  const [faiMinted, assetsIn] = await Promise.all([
    compContract.methods.mintedFAIs(accountAddress).call(),
    compContract.methods.getAssetsIn(accountAddress).call(),
  ])

  const _faiMinted = new BigNumber(faiMinted).div(new BigNumber(10).pow(18))

  const assetList = await Promise.all(
    marketsConfig.map(async (item, index) => {
      let market = (marketData.markets || []).find((ele) => ele.underlyingSymbol === item.symbol)
      if (!market) market = {}
      const asset = {
        key: index,
        id: item.id,
        img: item.asset,
        vimg: item.fasset,
        name: market.underlyingSymbol || '',
        symbol: market.underlyingSymbol || '',
        tokenAddress: item.symbol === 'BNB' ? '' : getAddress(item.token.address),
        fsymbol: market.symbol,
        ftokenAddress: getAddress(item.ftoken.address),
        supplyApy: new BigNumber(market.supplyApy || 0),
        borrowApy: new BigNumber(market.borrowApy || 0),
        ftsSupplyApy: new BigNumber(market.ftsSupplyApy || 0),
        ftsBorrowApy: new BigNumber(market.ftsBorrowApy || 0),
        totalSupplyApy: new BigNumber(market.totalSupplyApy || 0),
        totalBorrowApy: new BigNumber(market.totalBorrowApy || 0),
        collateralFactor: new BigNumber(market.collateralFactor || 0).div(1e18),
        underlyingPriceUSD: new BigNumber(market.underlyingPriceUSD || 0),
        liquidity: new BigNumber(market.liquidity || 0),
        borrowCaps: new BigNumber(market.borrowCaps || 0),
        totalBorrows: new BigNumber(market.totalFBorrows || 0),
        walletBalance: new BigNumber(0),
        supplyBalance: new BigNumber(0),
        borrowBalance: new BigNumber(0),
        isEnabled: false,
        collateral: false,
        percentOfLimit: '0',
        hypotheticalLiquidity: '',
      }

      const tokenDecimal = item.token ? item.token.decimals : 18
      const vBepContract = getVbepContract(null, item.ftoken)
      asset.collateral = assetsIn.map((v) => v.toLowerCase()).includes(asset.ftokenAddress.toLowerCase())
      // wallet balance
      if (item.id !== 'bnb') {
        const tokenContract = getTokenContract(null, item.token)
        const [walletBalance, allowBalance] = await Promise.all([
          tokenContract.methods.balanceOf(accountAddress).call(),
          tokenContract.methods.allowance(accountAddress, asset.ftokenAddress).call(),
        ])
        asset.walletBalance = new BigNumber(walletBalance).div(new BigNumber(10).pow(tokenDecimal))
        const _allowBalance = new BigNumber(allowBalance).div(new BigNumber(10).pow(tokenDecimal))
        asset.isEnabled = _allowBalance.isGreaterThan(asset.walletBalance)
      } else if (web3) {
        let bnbBalance = '0'
        bnbBalance = await web3.eth.getBalance(accountAddress)
        asset.walletBalance = new BigNumber(bnbBalance).div(new BigNumber(10).pow(tokenDecimal))
        asset.isEnabled = true
      }
      const [supplyBalance, borrowBalance, totalBalance] = await Promise.all([
        vBepContract.methods.balanceOfUnderlying(accountAddress).call(),
        vBepContract.methods.borrowBalanceCurrent(accountAddress).call(),
        vBepContract.methods.balanceOf(accountAddress).call(),
      ])
      // supply balance
      asset.supplyBalance = new BigNumber(supplyBalance).div(new BigNumber(10).pow(tokenDecimal))

      // borrow balance
      asset.borrowBalance = new BigNumber(borrowBalance).div(new BigNumber(10).pow(tokenDecimal))

      // percent of limit
      asset.percentOfLimit = new BigNumber(totalBorrowLimit).isZero()
        ? '0'
        : asset.borrowBalance.times(asset.underlyingPriceUSD).div(totalBorrowLimit).times(100).dp(0, 1).toString(10)

      // hypotheticalLiquidity
      asset.hypotheticalLiquidity = await compContract.methods
        .getHypotheticalAccountLiquidity(accountAddress, asset.ftokenAddress, totalBalance, 0)
        .call()

      const supplyBalanceUSD = asset.supplyBalance.times(asset.underlyingPriceUSD)
      const borrowBalanceUSD = asset.borrowBalance.times(asset.underlyingPriceUSD)

      totalSupplyBalance = totalSupplyBalance.plus(supplyBalanceUSD)
      totalBorrowBalance = totalBorrowBalance.plus(borrowBalanceUSD)

      if (asset.collateral) {
        totalBorrowLimit = totalBorrowLimit.plus(supplyBalanceUSD.times(asset.collateralFactor))
      }

      totalLiquidity = totalLiquidity.plus(new BigNumber(market.totalSupplyUsd || 0))

      // Calculate fortress Earned
      const supplyState = await compContract.methods.fortressSupplyState(asset.ftokenAddress).call()
      const supplyIndex = supplyState.index
      let supplierIndex = await compContract.methods.fortressSupplierIndex(asset.ftokenAddress, accountAddress).call()

      if (+supplierIndex === 0 && +supplyIndex > 0) {
        supplierIndex = fortressInitialIndex
      }
      let deltaIndex = new BigNumber(supplyIndex).minus(supplierIndex)

      const supplierTokens = totalBalance
      const supplierDelta = new BigNumber(supplierTokens).multipliedBy(deltaIndex).dividedBy(1e36)
      fortressEarned = fortressEarned.plus(supplierDelta)

      const borrowState = await compContract.methods.fortressBorrowState(accountAddress).call()
      let borrowIndex = borrowState.index
      const borrowerIndex = await compContract.methods.fortressBorrowerIndex(asset.ftokenAddress, accountAddress).call()
      if (+borrowerIndex > 0) {
        deltaIndex = new BigNumber(borrowIndex).minus(borrowerIndex)
        const borrowBalanceStored = await vBepContract.methods.borrowBalanceStored(accountAddress).call()
        borrowIndex = await vBepContract.methods.borrowIndex().call()
        const borrowerAmount = new BigNumber(borrowBalanceStored).multipliedBy(1e18).dividedBy(borrowIndex)
        const borrowerDelta = borrowerAmount.times(deltaIndex).dividedBy(1e36)
        fortressEarned = fortressEarned.plus(borrowerDelta)
      }

      return asset
    }),
  )
  const fortressAccrued = await compContract.methods.fortressAccrued(accountAddress).call()
  fortressEarned = fortressEarned.plus(fortressAccrued).dividedBy(1e18)

  return {
    assetList,
    faiMinted: _faiMinted,
    totalLiquidity: totalLiquidity.toString(10),
    totalSupplyBalance: totalSupplyBalance.toString(10),
    totalBorrowBalance: totalBorrowBalance.plus(_faiMinted).toString(10),
    totalBorrowLimit: totalBorrowLimit.toString(10),
    fortressEarned: fortressEarned.isNegative() ? 0 : fortressEarned.toNumber(),
    accountAddress,
  }
}

export default fetchMarkets

const ftokens = {
  fts: {
    symbol: 'fFTS',
    address: {
      56: '0x854C266b06445794FA543b1d8f6137c35924C9EB',
      97: '',
    },
    decimals: 8,
  },
  usdc: {
    symbol: 'fUSDC',
    address: {
      56: '0x3ef88D7FDe18Fe966474FE3878b802F678b029bC',
      97: '',
    },
    decimals: 8,
  },
  usdt: {
    symbol: 'fUSDT',
    address: {
      56: '0x554530ecDE5A4Ba780682F479BC9F64F4bBFf3a1',
      97: '',
    },
    decimals: 8,
  },
  busd: {
    symbol: 'fBUSD',
    address: {
      56: '0x8BB0d002bAc7F1845cB2F14fe3D6Aae1D1601e29',
      97: '',
    },
    decimals: 8,
  },
  bnb: {
    symbol: 'fBNB',
    address: {
      56: '0xE24146585E882B6b59ca9bFaaaFfED201E4E5491',
      97: '',
    },
    decimals: 8,
  },
  btcb: {
    symbol: 'fBTCB',
    address: {
      56: '0x47BAA29244c342f1e6cDe11C968632E7403aE258',
      97: '',
    },
    decimals: 8,
  },
  eth: {
    symbol: 'fETH',
    address: {
      56: '0x5F3EF8B418a8cd7E3950123D980810A0A1865981',
      97: '',
    },
    decimals: 8,
  },
  ltc: {
    symbol: 'fLTC',
    address: {
      56: '0xE75b16cc66F8820Fb97f52f0C25F41982ba4daF3',
      97: '',
    },
    decimals: 8,
  },
  xrp: {
    symbol: 'fXRP',
    address: {
      56: '0xa7FB72808de4FFcaCf9a815Bd1CcbE70F03b54ca',
      97: '',
    },
    decimals: 8,
  },
  ada: {
    symbol: 'fADA',
    address: {
      56: '0x4C0933453359733b4867DfF1145A9a0749931A00',
      97: '',
    },
    decimals: 8,
  },
  dai: {
    symbol: 'fDAI',
    address: {
      56: '0x5F30fDDdCf14a0997a52fdb7D7F23b93F0f21998',
      97: '',
    },
    decimals: 8,
  },
  beth: {
    symbol: 'fBETH',
    address: {
      56: '0x8ed1f4c1326E5d3C1b6E99Ac9E5EC6651E11e3Da',
      97: '',
    },
    decimals: 8,
  },
  dot: {
    symbol: 'fDOT',
    address: {
      56: '0x8fc4f7A57BB19E701108b17d785a28118604a3D1',
      97: '',
    },
    decimals: 8,
  },
  'cake-lp-fts-bnb-v2': {
    symbol: 'fCake-LP-FTS-BNB-v2',
    address: {
      56: '0xFF6296Fd1Cf18fDFCa02824801ebe1481b974391',
      97: '',
    },
    decimals: 8,
  },
}

export default ftokens
